import React from 'react'
import { type LucideProps } from 'lucide-react'

const Wellness: React.FC<LucideProps> = (props: LucideProps) => (
  <svg viewBox="0 0 28 27" fill="currentColor" {...props}>
    <path d="M25.7259 13.3899H11.4444C11.5583 13.1946 11.6284 12.9707 11.6284 12.7287V11.053C11.6284 10.3236 11.035 9.73047 10.3059 9.73047H3.26969C2.54053 9.73047 1.9472 10.3236 1.9472 11.053V12.7287C1.9472 12.9951 2.02781 13.2424 2.16385 13.4502C1.45558 13.6368 0.929199 14.277 0.929199 15.043V16.5489C0.929199 17.4603 1.67084 18.202 2.58231 18.202H2.74364V26.6031C2.74364 26.7858 2.89162 26.9337 3.07426 26.9337C3.2569 26.9337 3.40488 26.7858 3.40488 26.6031V24.0621L9.26542 18.2018H19.0427L24.9033 24.0621V26.6031C24.9033 26.7858 25.0513 26.9337 25.2339 26.9337C25.4165 26.9337 25.5645 26.7858 25.5645 26.6031V18.202H25.7258C26.6373 18.202 27.379 17.4603 27.379 16.5489V15.043C27.379 14.1315 26.6373 13.3899 25.7258 13.3899H25.7259ZM2.60856 11.053C2.60856 10.6884 2.90518 10.3917 3.2698 10.3917H10.306C10.6706 10.3917 10.9672 10.6884 10.9672 11.053V12.7287C10.9672 13.0932 10.6706 13.3899 10.306 13.3899H3.2698C2.90518 13.3899 2.60856 13.0932 2.60856 12.7287V11.053ZM3.40497 23.127V18.2018H8.33041L3.40497 23.127ZM24.9034 23.127L19.9779 18.2018H24.9034V23.127ZM26.7178 16.5487C26.7178 17.0957 26.2729 17.5406 25.7259 17.5406H2.58241C2.03546 17.5406 1.59055 17.0957 1.59055 16.5487V15.0428C1.59055 14.4959 2.03546 14.051 2.58241 14.051H25.7259C26.2729 14.051 26.7178 14.4959 26.7178 15.0428V16.5487Z" fill="currentColor"/>
    <path d="M14.0291 8.85992C14.0692 8.87639 14.1116 8.88446 14.1541 8.88446C14.1966 8.88446 14.239 8.87639 14.279 8.85992C14.4376 8.79534 18.1691 7.24136 18.8543 4.23563C19.2663 2.4298 18.2679 1.12503 17.1662 0.677035C16.0707 0.231468 14.9309 0.558541 14.1542 1.50778C13.3777 0.558866 12.2384 0.231137 11.1428 0.677035C10.0406 1.12519 9.0422 2.42991 9.45418 4.23563C10.1398 7.24125 13.8707 8.79517 14.0294 8.85992H14.0291ZM11.3916 1.28951C11.6121 1.19975 11.8603 1.14583 12.1186 1.14583C12.7191 1.14583 13.3753 1.43772 13.872 2.25135C13.932 2.3495 14.0388 2.40956 14.1541 2.40956C14.2694 2.40956 14.3762 2.3495 14.4363 2.25135C15.147 1.08707 16.1837 0.991186 16.9171 1.28952C17.7682 1.63562 18.5359 2.65948 18.2096 4.0885C17.6666 6.4697 14.7918 7.90167 14.154 8.19262C13.5163 7.9017 10.6417 6.46978 10.0983 4.0885C9.77234 2.65914 10.5399 1.63565 11.3913 1.28952L11.3916 1.28951Z" fill="currentColor"/>
  </svg>
)

export default Wellness
